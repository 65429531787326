import React from 'react';
import './TwoWheelerInsurance.scss';

export class TwoWheelerInsurance extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Two Wheeler Insurance</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-sm-center mb-30">
                    <h2 className="h2-v2 text-left">Two Wheeler Insurance: Buy/Renew Two Wheeler Insurance Policy Online Instantly</h2>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">What is Two Wheeler Insurance?</h3>
                      <p></p>
                      Two wheeler Insurance protects the policy holder for damage to his/her insured / private two wheeler due to:
                      <ul>
                        <li>Natural disasters such as earthquake, flood, storm, etc.</li>
                        <li>Man-made disasters such as accident, theft, fire, riots, malicious act, etc.</li>
                        <li>While in transit by rail/road, lift, elevator</li>
                      </ul>
      <p>&nbsp;</p>
      <h3 className="font-bold">What are two types of Two Wheeler  Insurance available in India?</h3>
      The two types of Two Wheeler  insurance are
Third Party (or Liability Only policy):   this covers only the injury or death of a third person and/or damage to  third person's - on account of any accident caused by insured's two wheeler.  Third Party Insurance also includes compulsory personal accident cover for the owner-driver. This type of policy does not cover any damages to own two wheeler.  As per Indian laws, it is mandatory for every two wheeler owner to take Third Party Cover.  Rs. 1 lakh for Scooters/Motor Cycles.
Comprehensive (or Package policy): To cover for loss/damage to your private/passenger two wheeler - you must purchase Comprehensive Policy covering Third Party and Own Damage (OD) covers.  Own Damage covers any damage caused to own two-wheeler
<p>&nbsp;</p>
      <h3 className="font-bold">What are the various insurance covers available for Two Wheeler  insurance?</h3>
      Two Wheeler Comprehensive Policy covers damage to your two wheeler due to:
      
      <ul>
        <li>Accidents</li>
        <li>Any damage caused to own two wheeler due to accident or collision with any other vehicle Theft</li>
        <li>It covers loss arising due to theft of your two wheeler or malicious acts</li>
        <li>Third Party Damage -  damage caused to third party vehicle or property by your two wheeler</li>
        <li>Natural calamities like floods, earthq​uake, fire and more</li>
        <li>Man-made calamities like vandalism, riots and terror attacks</li>
        <li>Damage due to Explosion / Hailstorm /Terrorism /Frost /Inundation / Self-ignition /Lightening /Cyclone / Tempest / Riot & Strikes / Hurricane  / Landlside / Rockslide /Typhoon /  Transit by Rail/Road/Air
</li>
      </ul>
      <p>&nbsp;</p>
      <h3 className="font-bold">How is Two Wheeler  Insurance Premium calculated?</h3>
      Two wheeler  Insurance Premium depends upon the following factors:
      <ul>
          <li>
          Insured Declared Value  (IDV)
          </li>
          <li>
          Cubic Capacity
          </li>
          <li>
          Geographical Zone
          </li>
          <li>
          Age of the two wheeler
          </li>
      </ul>  
      <p>&nbsp;</p> 
      <h3 className="font-bold">What is two wheeler third party insurance policy, also known as ‘party liability only’?</h3>
      <div>
        Two Wheeler thirdparty insurance policy has following 3 covers:
        <ul>
          <li>It protects you against possible legal and financial obligations  (i.e  including death, bodily harm, injury or death, and damage to property) that may arise from damages caused to a third party. This third party can either be a person or his/her property</li>
          <li>The premium paid is significantly lower </li>
          <li>Very less and simple documentation is needed to purchase a third party Insurance Two wheeler policy</li>
        </ul>   
      </div>
      <p>&nbsp;</p>
      <h3 className="font-bold">FAQs</h3>
      <br/>
      <div className="font-bold">Who can buy Two Wheeler  Insurance?</div>
      <div>Any two wheeler owner who has registered his two wheeler in his/her name with any Regional Transport Authority in India can buy two wheeler insurance</div>
      <br/>
      <div className="font-bold">What is No Claim bonus and how is it calculated?</div>
      <div>No- claim bonus (NCB) is available on timely renewal of policy, ranging from 20% to 50%, depending upon the type of vehicle and the number of years for which no claim has been made  --- for 5 or more claimfree years you can claim 50% NCB on OD premium</div>
      <br/>
      <div className="font-bold">What is Return to Invoice cover for my Two Wheeler?</div>
      <div>
        Return to Invoice – Your two wheeler's IDV is calculated basis depreciation (due to vintage of the two wheeler). This add-on cover ensures that in case of a claim  - the claim amount in case of a total loss of the two-wheeler will be the invoice value as opposed to IDV.
      </div>
      <br/>
      <div className="font-bold">What are key factors impacting Two Wheeler Premium ?</div>
      <div>
        Factors Affecting Two-Wheeler Insurance Premium include
- Make and Model of the two wheeler /   Year of Registration  /  Place of Registration (geographical zone) /  Policy Holder's age /  type of coverage /  Engine Type and Capacity /  Add-on covers if any  /  NCB  / Safety device (anti theft lock etc) installed
      </div>
      <br/>
      <div className="font-bold">What is compulsory deductible and how is it beneficial?</div>
      <div>"Some insurance companies offer the Engine Add-on cover. 
        Two wheeler insurance policy  provides for an option of having a compulsory deductible (CD)  -  CD is the amount you need to pay before the claim is settled by the insurer. 
If the customer opts for voluntary compulsory deductible on the Insured Declared Value (IDV)  - it helps reduce the two wheeler OD premium.        
      </div>
      <br/>
      <div className="font-bold">Are there any long-term two wheeler insurance policies available?</div>
      <div>
      Yes-  many General Insurance companies offer long-term (upton 3 years period) Two wheeler Insurance policies. Some benefits include:
      <ul>
        <li>Lower OD premium</li>
        <li>Nil increase in third party premium rates for upto 3 years and service tax</li>
        <li>No need to worry about annual renewals</li>
        <li>Transfer of upto 50% of No Claim Bonus (offered by few insurers)</li>
      </ul>
      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}