import React from 'react';
import './header.scss';

export class ProductsMenu extends React.Component {

  render() {
    return (
      <div className="navbar-dropdown-inner1">
        <div className="header-container">
          <div className="header-products">
            <div className="header-products__items">
              <div className="header-products__items-image-section">
                <p className="product-name">Motor</p></div><div className="sub-products-container">
                <ul className="sub-products-container-list">
                  <li><a href="/carinsurance">Car Insurance</a></li>
                  <li><a href="/twowheelerinsurance">Two Wheeler Insurance</a></li>
                  <li><a href="/gcvinsurance">Goods Carrying Vehicle Insurance</a></li>
                  <li><a href="/autorickshawinsurance">Auto Rickshaw Insurance</a></li>
                  <li><a href="/pcvinsurance">Passenger Carrying Vehicle Insurance</a></li>
                  <li><a href="/tractorinsurance">Tractor Insurance</a></li>
                </ul>
              </div>
            </div>
            <div className="header-products__items"><div className="header-products__items-image-section">
              <p className="product-name">Health Insurance and Other Products</p></div><div className="sub-products-container">
              <ul className="sub-products-container-list"><li><a href="/healthinsurance">Health Insurance</a></li>
              <li><a href="/travelinsurance">Travel Insurance</a></li>
              <li><a href="/marineinsurance">Marine Insurance</a></li>
              <li><a href="/homeinsurance">Home Insurance</a></li>
              <li><a href="/shopinsurance">Shop Insurance</a></li>
              </ul>
              </div>
            </div>
            </div>
        </div>
      </div>
    );
  }

}