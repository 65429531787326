import React from 'react';
import './CarInsurance.scss';

export class CarInsurance extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Car Insurance</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-sm-center mb-30">
                    <h2 className="h2-v2 text-left">Car Insurance: Buy/Renew Car Insurance Policy Online Instantly</h2>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">What is Car Insurance?</h3>
                      <p></p>
                      Car Insurance protects the policy holder for damage to the insured or private car due to 
                      <ul>
                        <li>Natural disasters such as earthquake, flood, storm, etc.</li>
                        <li>Man-made disasters such as accident, theft, fire, riots, malicious act, etc.</li>
                        <li>Vehicle In transit by rail / road, lift, elevator</li>
                      </ul>      
      <p>&nbsp;</p>
    <h3>What are two types of Car Insurance available in India?</h3>
    The two types of car insurance are:
    <div><b>Third Party (or Liability Only policy):</b>  This covers only the injury or death of a third person and/or damage to third person's property - on account of any accident caused by insured's car. Third party policy also includes compulsory personal accident cover for the owner-driver. This type of policy does not cover any damages to own car.  As per Indian laws, it is mandatory for every car owner to take Third Party Cover.
</div>
    <div><b>Comprehensive (or Package policy):</b> To cover for loss/damage to your private /passenger car - you must purchase Comprehensive Policy covering Third Party and Own Damage covers.  
</div>
    <p>&nbsp;</p>
    <h3>What are the various Insurance covers available for private car?</h3>
    Car Comprehensive Policy covers damage to your car due to:
    <ul>
      <li>Accidents -  any damage caused to own car due to accident or collision with any other vehicle
      </li>
      <li>Theft - it covers loss arising due to theft of your car or malicious acts
      </li>
      <li>Third Party Damage -  damage caused to third party vehicle or property by your car
      </li>
      <li>Natural calamities like floods, earthq​uake, fire and more
      </li>
      <li>Man-made calamities like vandalism, riots and terror attacks
      </li>
      <li>Damage due to Explosion / Hailstorm /Terrorism /Frost /Inundation / Self-ignition /Lightening /Cyclone / Tempest / Riot & Strikes / Hurricane  / Landlside / Rockslide /Typhoon /  Transit by Rail/Road/Air
      </li>
    </ul>
    <p>&nbsp;</p>
    <h3>How is Car Insurance Premium calculated?</h3>
      Car Insurance Premium depends upon the following factors:
       <ul>
         <li>Insured Declared Value  (IDV)</li>
         <li>Cubic Capacity</li>
         <li>Geographical Zone</li>
         <li>Age of the car</li>
         <li>Add-on covers -  Loss or damage to accessories fitted in the vehicle such as stereos, fans, air-conditioners  /  personal accident cover to paid driver and passengers  /  legal liability of employees</li>
       </ul> 


    <p>&nbsp;</p>
    <h3>FAQs</h3>
    <br/>
    <div className='font-bold'>Who can buy Car Insurance?</div>
    
    <div>Any car owner who has registered his private car in his/her name with any Regional Transport Authority in India can buy car insurance
    </div>
    <br/>
    <div className='font-bold'>What is Zero Depreciation Cover?</div>
    <div>
        Zero Depreciation Cover allows the policy holder to you to nullify the depreciation charged on your car and its parts and gives you the full value of repairs, costs and replacements during a claim. This implies that your car does not lose value in the market as the depreciation of your car is not considered by your insurer.  
    </div>
    <div>
    By availing of zero depreciation, the policy holder will receive full value for his/her car and all its spare parts. Bumper to bumper cover is expensive but is worth the additional amount paid.
Zero Depreciation cover is advisable for cars upto 4-5 years vintage.  
    </div>
    <br/>
    <div className='font-bold'>What is Consumables Cover?</div>
    <div>
      For car insurance - this add-on cover takes care of the cost for all consumables such as engine oils, screws, nuts and bolts, grease, etc replaced after accident repair.
    </div>
    <br/>
    <div className='font-bold'>What is Conveyance Add-on Cover?</div>
    <div>
    If your car is getting repaired in an authorised garage and after acceptance of the claim by the Insurance company - this cover enables the policy holder to get paid by the insurance company for daily transportation to place of work and back till the time the car is handed back after repairs
    </div>
    <br/>
    <div className='font-bold'>What is Engine Protector Add-on Cover?</div>
    <div>Some Insurance companies offer the Engine Add-on cover 
      This covers all expenses arising due to water ingression, oil leakage, hydrostatic lock and more. You can save upto 40% of the amount you would spend on getting your car’s engine fixed otherwise. 
      Key features of this add-on cover are
      <ul>
        <li>Protection against damages to engine and engine parts of your car</li>
        <li>Protection against damages to differential parts of your car</li>
        <li>Protection against damages to gear box and gear box parts of your car</li>
      </ul>
         
    </div>
    <br/>
    <div className='font-bold'>What is Road Side Assistance Cover?</div>
    <div>Road Side Assistance Cover typically covers support for following situations -  
      <ul>
        <li>Flat /Dead Battery  - If your car is at a halt due to issues in your battery -  then this cover enables paying for all labor and conveyance costs involved.</li>
        <li>Flat Tyre  - If you are stranded with your car due to a flat tyre and have no help available, then this cover will arrange for the mechanic /technician to help replace the tyre or help you out with a spare tyre.    
        </li>
        <li>Spare Keys  - In case you lose your car keys - then this cover enables for pickup and delivery of your spare keys and/or also help unlock your car with the help of technicians.
        </li>
        <li>Fuel Assistance  - this cover enables you to get upto 5 litres of petrol /diesel to the location you’re stuck at.
        </li>
        <li>Towing Facility -   if your car has a serious problem and cannot be repaired on the spot ; and it needs to be sent to workshop/garage - then this cover provides you with towing facility.
        </li>
        <li>Medical Help Coordination - in the very unfortunate circumstances of you requiring medical help due to an accident midway - then this cover wil provide coordination with the nearest hospital /medical center.
        </li>
      </ul>
      
    </div>
    <br/>
    <div className='font-bold'>What is Return to Invoice (RTI) cover?</div>
    <div>Return to Invoice is an add-on cover which states that - during car's theft or damages beyond repair - then the policy holder gets the benefit of getting back the complete amount of the car's invoice value, including the cost of registering a new car and road tax
    </div>
    <br/>
    <div className='font-bold'>What are Precautions to be taken while availing of car insurance?</div>
    <div>As a policy holder, one must take following precautions:
      <ul>
        <li>Dont allow your policy to lapse and lose Nó Claim Bonus subsequently. Please ensure your car insurance policy is renewed before expiry date.</li>
        <li>Dont hand over your car to anyone unless the person has a valid driving license.</li>
        <li>Dont drive your car or allow anyone to drive car under influence of drugs /alcohol /any other intoxicants.</li>
        <li>Dont leave your keys in the car and/or leave any door unlocked when you leave the car.</li>
      </ul>
    </div>
    <br/>
    <div className='font-bold'>How can a policy holder get additional discounts?</div>
    <div>Discounts are offered on premiums for certain conditions, such as having an anti-theft device installed in the car /  age and profession (doctor /CA etc)  of the policy holder 
    </div>
    <br/>
    <div className='font-bold'>Can I buy Car Insurance online? If yes- then how?</div>
    <div>Yes - you can buy Car Insurance online. To purchase car insurance online - you can login to website of insurance companies offering this facility. During this process, you must keep the soft copies of the following documents ready ---
       <ul>
         <li>Vehicle details incl make /model /year of manufacture and car registration number</li>
         <li>Owner's valid diriving license</li>
         <li>Bank details</li>
         <li>Receipts of any taxes paid</li>
         <li>Completed claim form</li>
         <li>Filled up Insurance form as asked by insurance company</li>
       </ul>
    </div>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}