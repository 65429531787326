import React from 'react';
import './ProductsList.scss';
import CarInsuranceImage from '../../assets/images/car-insurance.png';
import HealthInsuranceImage from '../../assets/images/health_insurance.jpg';
import ShopKeeperInsuranceImage from '../../assets/images/shopkeeper_insurance.jpg';
import HomeInsuranceImage from '../../assets/images/householder_insurance.jpg';
import TravelInsuranceImage from '../../assets/images/travel.png';
import TwoWheelerInsuranceImage from '../../assets/images/car-insurance.png';
import GCVInsuranceImage from '../../assets/images/gcv.jpg';
import PCVInsuranceImage from '../../assets/images/pcv.jpg';
import AutoRickshawInsuranceImage from '../../assets/images/auto_rickshaw.png';
import TractorInsuranceImage from '../../assets/images/tractor_insurance.jpg';
import MarineInsuranceImage from '../../assets/images/marine_insurance.jpeg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import { faMotorcycle } from '@fortawesome/free-solid-svg-icons'
import { faTruckFast } from '@fortawesome/free-solid-svg-icons'
import { faBus } from '@fortawesome/free-solid-svg-icons'
import { faTractor } from '@fortawesome/free-solid-svg-icons'
import { faHeartPulse } from '@fortawesome/free-solid-svg-icons'
import { faPlaneDeparture } from '@fortawesome/free-solid-svg-icons'
import { faShip } from '@fortawesome/free-solid-svg-icons'
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons'
import { faStore } from '@fortawesome/free-solid-svg-icons'

export class ProductsList extends React.Component<{}, { showProductsSubMenu: boolean }> {

  constructor(props: any) {
    super(props);
    this.state = {
      showProductsSubMenu: false
    };
  }

  showSubMenu = () => {
    this.setState({ showProductsSubMenu: true });
  }

  hideSubMenu = () => {
    this.setState({ showProductsSubMenu: false });
  }

  render() {
    return (
      <div className="more-extra-products">
        <section className="section-pad">
          <div className="container">
            <div className="row">
              <div className="col-sm-10 offset-md-1">
                <div className="section-text-wrap" data-aos="zoom-in" data-aos-duration="3000">
                  <h2 className="section-title txt-center">WHAT WOULD YOU LIKE TO <span className="brand-color">EEZEEINSURE</span> TODAY?</h2>
                </div>
              </div>
              <div className="col-sm-12 col-md-10 offset-md-1" data-aos="flip-up" data-aos-duration="3000">
                <div className="more-products-wrap">
                  <ul className="more-extra-products-wrap clearfix">
                    <li className="more-products__list col-4 col-sm-3 col-lg-2">
                      <a href="/carinsurance" className="more-products__link">
                        <div className="more-products__img-wrap car-icon">
                          <div className="circle-rotation"></div>
                          <FontAwesomeIcon icon={faCar} />
                        </div>
                        <div className="more-products__text">Car</div>
                      </a>
                    </li>
                    <li className="more-products__list col-4 col-sm-3 col-lg-2">
                      <a href="/twowheelerinsurance" className="more-products__link">
                        <div className="more-products__img-wrap bike-icon">
                          <div className="circle-rotation"></div>
                          <FontAwesomeIcon icon={faMotorcycle} />
                        </div>
                        <div className="more-products__text">Two Wheeler</div>
                      </a>
                    </li>
                    <li className="more-products__list col-4 col-sm-3 col-lg-2">
                      <a href="/healthinsurance" className="more-products__link">
                        <div className="more-products__img-wrap">
                          <div className="circle-rotation"></div>
                          <FontAwesomeIcon icon={faHeartPulse} />
                        </div>
                        <div className="more-products__text">Health</div>
                      </a>
                    </li>
                    <li className="more-products__list col-4 col-sm-3 col-lg-2">
                      <a href="/travelinsurance" className="more-products__link">
                        <div className="more-products__img-wrap plane-icon">
                          <div className="circle-rotation"></div>
                          <FontAwesomeIcon icon={faPlaneDeparture} />
                        </div>
                        <div className="more-products__text">Travel</div>
                      </a>
                    </li>
                    <li className="more-products__list col-4 col-sm-3 col-lg-2">
                      <a href="/pcvinsurance" className="more-products__link">
                        <div className="more-products__img-wrap bus-icon">
                          <div className="circle-rotation"></div>
                          <FontAwesomeIcon icon={faBus} />
                        </div>
                        <div className="more-products__text">Passenger Vehicle</div>
                      </a>
                    </li>
                    <li className="more-products__list col-4 col-sm-3 col-lg-2">
                      <a href="/gcvinsurance" className="more-products__link">
                        <div className="more-products__img-wrap goods-icon">
                          <div className="circle-rotation"></div>
                          <FontAwesomeIcon icon={faTruckFast} />
                        </div>
                        <div className="more-products__text">GCV</div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="more-products-wrap">
                  <ul className="more-extra-products-wrap clearfix">
                  <li className="more-products__list col-4 col-sm-3 col-lg-2">
                      <a href="/autorickshawinsurance" className="more-products__link">
                        <div className="more-products__img-wrap auto-icon">
                        <div className="circle-rotation"></div>
                          <img className="more-products__img pw-img loaded auto-img"
                            alt="Auto Rickshaw"
                            src={AutoRickshawInsuranceImage} />
                        </div>
                        <div className="more-products__text">Auto Rickshaw</div>
                      </a>
                    </li>
                    <li className="more-products__list col-4 col-sm-3 col-lg-2">
                      <a href="/tractorinsurance" className="more-products__link">
                        <div className="more-products__img-wrap tractor-icon">
                          <div className="circle-rotation"></div>
                          <FontAwesomeIcon icon={faTractor} />
                        </div>
                        <div className="more-products__text">Tractor</div>
                      </a>
                    </li>
                    <li className="more-products__list col-4 col-sm-3 col-lg-2">
                      <a href="/shopinsurance" className="more-products__link">
                        <div className="more-products__img-wrap shop-icon">
                          <div className="circle-rotation"></div>
                          <FontAwesomeIcon icon={faStore} />
                        </div>
                        <div className="more-products__text">Shop</div>
                      </a>
                    </li>
                    <li className="more-products__list col-4 col-sm-3 col-lg-2">
                      <a href="/marineinsurance" className="more-products__link">
                        <div className="more-products__img-wrap marine-icon">
                          <div className="circle-rotation"></div>
                          <FontAwesomeIcon icon={faShip} />
                        </div>
                        <div className="more-products__text">Marine</div>
                      </a>
                    </li>
                    <li className="more-products__list col-4 col-sm-3 col-lg-2">
                      <a href="/homeinsurance" className="more-products__link">
                        <div className="more-products__img-wrap home-icon">
                          <div className="circle-rotation"></div>
                          <FontAwesomeIcon icon={faHouseChimney} />
                        </div>
                        <div className="more-products__text">Home</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

}