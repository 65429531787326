import React from 'react';
import './SemiFooter.scss';

export class SemiFooter extends React.Component<{}, 
  { showProductsAcc: boolean, 
  showOtherGuidesAcc: boolean,
  showIncomeTaxGuidesAcc: boolean,
  showIndividualProductsAcc: boolean,
  showIndividualHealthAcc: boolean,
  showIndividualCarAcc: boolean,
  showIndividualBikeAcc: boolean,
  showIndividualOtherGuidesAcc: boolean,
  showIndividualCalculatorsAcc: boolean,
  showIndividualFinanceGuidesAcc: boolean,
  showIndividualTrafficRulesAcc: boolean,
  showIndividualIncomeTaxGuidesAcc: boolean,
  showIndividualRTOOfficeAcc: boolean,
  showIndividualImpLinksAcc: boolean,
  showIndividualModelsAcc: boolean
 }> {

  constructor(props: any) {
    super(props);
    
    if(document.body.clientWidth > 576) {
      this.state = {
        showProductsAcc: true,
        showOtherGuidesAcc: false,
        showIncomeTaxGuidesAcc: false,
        showIndividualProductsAcc: false,
        showIndividualHealthAcc: false,
        showIndividualCarAcc: false,
        showIndividualBikeAcc: false,
        showIndividualOtherGuidesAcc: false,
        showIndividualCalculatorsAcc: false,
        showIndividualFinanceGuidesAcc: false,
        showIndividualTrafficRulesAcc: false,
        showIndividualIncomeTaxGuidesAcc: false,
        showIndividualRTOOfficeAcc: false,
        showIndividualImpLinksAcc: false,
        showIndividualModelsAcc: false
      };
    } else {
      this.state = {
        showProductsAcc: false,
        showOtherGuidesAcc: false,
        showIncomeTaxGuidesAcc: false,
        showIndividualProductsAcc: false,
        showIndividualHealthAcc: false,
        showIndividualCarAcc: false,
        showIndividualBikeAcc: false,
        showIndividualOtherGuidesAcc: false,
        showIndividualCalculatorsAcc: false,
        showIndividualFinanceGuidesAcc: false,
        showIndividualTrafficRulesAcc: false,
        showIndividualIncomeTaxGuidesAcc: false,
        showIndividualRTOOfficeAcc: false,
        showIndividualImpLinksAcc: false,
        showIndividualModelsAcc: false
      };
    }
  }

  toggleAcc = (accType: string) => {
    if(accType === 'otherproducts') {
      if(this.state.showProductsAcc) {
        this.setState({ showProductsAcc: false });
      } else {
        this.setState({ showProductsAcc: true });
      }   
    } else if(accType === 'otherguides') {
      if(this.state.showOtherGuidesAcc) {
        this.setState({ showOtherGuidesAcc: false });
      } else {
        this.setState({ showOtherGuidesAcc: true });
      }   
    } else if(accType === 'incometaxguides') {
      if(this.state.showIncomeTaxGuidesAcc) {
        this.setState({ showIncomeTaxGuidesAcc: false });
      } else {
        this.setState({ showIncomeTaxGuidesAcc: true });
      }   
    }
  }

  toggleSingleAcc = (accType: string) => {
    if(document.body.clientWidth > 576) {
      return false;
    }
    if(accType === 'products') {
      if(this.state.showIndividualProductsAcc) {
        this.setState({ showIndividualProductsAcc: false });
      } else {
        this.setState({ showIndividualProductsAcc: true });
      }   
    } else  if(accType === 'healthinsuranceguides') {
      if(this.state.showIndividualHealthAcc) {
        this.setState({ showIndividualHealthAcc: false });
      } else {
        this.setState({ showIndividualHealthAcc: true });
      }   
    } else  if(accType === 'carinsuranceguides') {
      if(this.state.showIndividualCarAcc) {
        this.setState({ showIndividualCarAcc: false });
      } else {
        this.setState({ showIndividualCarAcc: true });
      }   
    } else  if(accType === 'bikeinsuranceguides') {
      if(this.state.showIndividualBikeAcc) {
        this.setState({ showIndividualBikeAcc: false });
      } else {
        this.setState({ showIndividualBikeAcc: true });
      }   
    } else  if(accType === 'otherguides') {
      if(this.state.showIndividualOtherGuidesAcc) {
        this.setState({ showIndividualOtherGuidesAcc: false });
      } else {
        this.setState({ showIndividualOtherGuidesAcc: true });
      }   
    } else  if(accType === 'calculators') {
      if(this.state.showIndividualCalculatorsAcc) {
        this.setState({ showIndividualCalculatorsAcc: false });
      } else {
        this.setState({ showIndividualCalculatorsAcc: true });
      }   
    } else  if(accType === 'financeguides') {
      if(this.state.showIndividualFinanceGuidesAcc) {
        this.setState({ showIndividualFinanceGuidesAcc: false });
      } else {
        this.setState({ showIndividualFinanceGuidesAcc: true });
      }   
    } else  if(accType === 'trafficrules') {
      if(this.state.showIndividualTrafficRulesAcc) {
        this.setState({ showIndividualTrafficRulesAcc: false });
      } else {
        this.setState({ showIndividualTrafficRulesAcc: true });
      }   
    } else  if(accType === 'incometaxguides') {
      if(this.state.showIndividualIncomeTaxGuidesAcc) {
        this.setState({ showIndividualIncomeTaxGuidesAcc: false });
      } else {
        this.setState({ showIndividualIncomeTaxGuidesAcc: true });
      }   
    } else  if(accType === 'rtooffices') {
      if(this.state.showIndividualRTOOfficeAcc) {
        this.setState({ showIndividualRTOOfficeAcc: false });
      } else {
        this.setState({ showIndividualRTOOfficeAcc: true });
      }   
    } else  if(accType === 'importantlinks') {
      if(this.state.showIndividualImpLinksAcc) {
        this.setState({ showIndividualImpLinksAcc: false });
      } else {
        this.setState({ showIndividualImpLinksAcc: true });
      }   
    } else  if(accType === 'models') {
      if(this.state.showIndividualModelsAcc) {
        this.setState({ showIndividualModelsAcc: false });
      } else {
        this.setState({ showIndividualModelsAcc: true });
      }   
    }
  }

  render() {
    return (
      <div className="footercollapsen section">
        <div className="bg-gray">
          <div className="container-fluid collapse-heading-wrap"></div>
          <div className="container collapse-wrap">
            <div className="" id="" aria-expanded="false">
              <div className="py-15">
                <h3 className="footer-title"></h3>
                <div className="row row-main">
                  <div className="col-sm-10 offset-sm-1 row-main-list">
                    <div className="row">
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item active">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#" className="heading-text__link"
                                onClick={() => this.toggleSingleAcc("products")}>Other Products</a>
                            </div>
                            { !this.state.showIndividualProductsAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualProductsAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showProductsAcc || this.state.showIndividualProductsAcc) &&
                            <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/property-insurance/home-insurance" className="products-list__link" target="_blank">Home Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/property-insurance/fire-insurance" className="products-list__link" target="_blank">Fire Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/property-insurance/burglary-insurance" className="products-list__link" target="_blank">Burglary Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/property-insurance/building-insurance" className="products-list__link" target="_blank">Building Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/commercial-vehicle-insurance/bus-insurance" className="products-list__link" target="_blank">Bus Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/commercial-vehicle-insurance/tractor-insurance" className="products-list__link" target="_blank">Tractor Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/commercial-vehicle-insurance/commercial-van-insurance" className="products-list__link" target="_blank">Commercial Van Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/commercial-vehicle-insurance/passenger-carrying-vehicle-insurance" className="products-list__link" target="_blank">Passenger Carrying Vehicle Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/commercial-vehicle-insurance/heavy-vehicle-insurance" className="products-list__link" target="_blank">Heavy Vehicle Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/commercial-vehicle-insurance/goods-carrying-vehicle-insurance" className="products-list__link" target="_blank">Goods Carrying Vehicle Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/brand/honda/activa" className="products-list__link" target="_blank">Activa Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/brand/tvs/jupiter" className="products-list__link" target="_blank">Jupiter Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/brand/royal-enfield/bullet" className="products-list__link" target="_blank">Bullet Insurance</a>
                                </li>
                              </ul>
                            </div>
                          }
                          
                        </div>
                      </div>
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item active">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#" 
                                onClick={() => this.toggleSingleAcc("healthinsuranceguides")}
                                className="heading-text__link">Health Insurance Guides</a>
                            </div>     
                            { !this.state.showIndividualHealthAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualHealthAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showProductsAcc || this.state.showIndividualHealthAcc) &&
                            <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/health-insurance/individual-health-insurance" className="products-list__link" target="_blank">Individual Health Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/health-insurance-plans-for-family" className="products-list__link" target="_blank">Family Health Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/health-insurance-for-parents" className="products-list__link" target="_blank">Health Insurance for Parents</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/health-insurance-premium-calculator" className="products-list__link" target="_blank">Health Insurance Premium Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/compare-health-insurance" className="products-list__link" target="_blank">Compare Health Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/health-insurance-for-senior-citizens" className="products-list__link" target="_blank">Health Insurance for Senior Citizens</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/health-insurance-with-maternity-cover" className="products-list__link" target="_blank">Health Insurance with Maternity Cover</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/corona-kavach" className="products-list__link" target="_blank">Corona Kavach Policy</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/corona-rakshak-policy" className="products-list__link" target="_blank">Corona Rakshak Policy</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/hindi" className="products-list__link" target="_blank">हेल्थ इन्शुरन्स</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/health-insurance-plans-for-family/hindi" className="products-list__link" target="_blank">फैमिली हेल्थ इन्शुरन्स</a>
                                </li>
                              </ul>
                            </div>
                          }
                          
                        </div>
                      </div>
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item active">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#" 
                                onClick={() => this.toggleSingleAcc("carinsuranceguides")} 
                                className="heading-text__link">Car Insurance Guides</a>
                            </div>
                            { !this.state.showIndividualCarAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualCarAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showProductsAcc  || this.state.showIndividualCarAcc) &&
                            <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/third-party-car-insurance" className="products-list__link" target="_blank">Third Party Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/comprehensive-car-insurance" className="products-list__link" target="_blank">Comprehensive Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/zero-depreciation-car-insurance" className="products-list__link" target="_blank">Zero Depreciation Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/ncb-in-car-insurance" className="products-list__link" target="_blank">NCB in Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/idv-calculator" className="products-list__link" target="_blank">IDV Calculator for Car</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/bumper-to-bumper-car-insurance-cover" className="products-list__link" target="_blank">Bumper to Bumper Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/car-insurance-calculator" className="products-list__link" target="_blank">Car Insurance Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/own-damage-car-insurance" className="products-list__link" target="_blank">Own Damage Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/difference-between-comprehensive-and-third-party-insurance" className="products-list__link" target="_blank">Comprehensive vs Third Party Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/compare-car-insurance" className="products-list__link" target="_blank">Compare Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/traffic-rules/how-to-find-vehicle-owner-details-by-registration-number" className="products-list__link" target="_blank">Find Vehicle Registration Details Online</a>
                                </li>
                              </ul>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item active">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#" 
                                onClick={() => this.toggleSingleAcc("bikeinsuranceguides")}  
                                className="heading-text__link">Bike Insurance Guides</a>
                            </div>
                            { !this.state.showIndividualBikeAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualBikeAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showProductsAcc  || this.state.showIndividualBikeAcc) &&
                            <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/third-party-bike-insurance" className="products-list__link" target="_blank">Third Party Bike Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/comprehensive-bike-insurance" className="products-list__link" target="_blank">Comprehensive Bike Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/what-is-zero-depreciation-bike-insurance" className="products-list__link" target="_blank">Zero Depreciation Bike Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/ncb-in-bike-insurance" className="products-list__link" target="_blank">NCB in Two Wheeler Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/idv-in-bike-insurance" className="products-list__link" target="_blank">IDV Value Calculator for Bike</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/addon-cover-in-two-wheeler-insurance" className="products-list__link" target="_blank">Add-on Cover in Bike Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/bike-insurance-premium-calculator" className="products-list__link" target="_blank">Bike Insurance Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/own-damage-bike-insurance" className="products-list__link" target="_blank">Own Damage Bike Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/comprehensive-vs-third-party-bike-insurance" className="products-list__link" target="_blank">Comprehensive vs Third Party Bike Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/compare-bike-insurance" className="products-list__link" target="_blank">Compare Bike Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/brand/royal-enfield" className="products-list__link" target="_blank">Royal Enfield Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/brand/tvs" className="products-list__link" target="_blank">TVS Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/brand/hero" className="products-list__link" target="_blank">Hero Bike Insurance</a>
                                </li>
                              </ul>
                            </div>
                          }      
                        </div>
                      </div> 
                    </div>
                    { !this.state.showProductsAcc &&
                      <span onClick={() => this.toggleAcc("otherproducts")}  className="material-icons sm-arrow-icon sm-arrow-d-icon">expand_more</span>
                    }
                    { this.state.showProductsAcc &&
                      <span onClick={() => this.toggleAcc("otherproducts")}  className="material-icons sm-arrow-icon sm-arrow-d-icon">expand_less</span>
                    }
                  </div>
                </div>
                <div className="row row-main">
                  <div className="col-sm-10 offset-sm-1 row-main-list">
                    <div className="row">
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item ">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#" 
                               onClick={() => this.toggleSingleAcc("otherguides")} 
                               className="heading-text__link">Other Guides</a>
                            </div>
                            { !this.state.showIndividualOtherGuidesAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualOtherGuidesAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showOtherGuidesAcc || this.state.showIndividualOtherGuidesAcc) && 
                            <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/guides/types-of-insurance" className="products-list__link" target="_blank">Types of Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/guides/types-of-general-insurance" className="products-list__link" target="_blank">Types of General Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/types-of-motor-insurance" className="products-list__link" target="_blank">Types of Motor Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/guides/how-to-get-international-driving-license-in-india" className="products-list__link" target="_blank">International Driving License</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/international-travel-insurance/visa-on-arrival-for-indians" className="products-list__link" target="_blank">Visa on Arrival for Indians</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/international-travel-insurance/visa-free-countries-for-indians" className="products-list__link" target="_blank">Visa Free Countries for Indians</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/difference-between-comprehensive-and-zero-depreciation-insurance" className="products-list__link" target="_blank">Comprehensive vs Zero Depreciation</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/coronavirus-health-insurance" className="products-list__link" target="_blank">Coronavirus Health Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/covid-19-assist" className="products-list__link" target="_blank">Coronavirus Symptoms Checker</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/international-travel-insurance/indian-passport-rank" className="products-list__link" target="_blank">Indian Passport Rank</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/health-insurance/digit-illness-group-insurance" className="products-list__link" target="_blank">Digit Illness Group Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/brand/hero/splendor" className="products-list__link" target="_blank">Hero Splendor Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/brand/suzuki/access" className="products-list__link" target="_blank">Access Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/two-wheeler-insurance/brand/tvs/scooty-pep" className="products-list__link" target="_blank">Scooty Insurance</a>
                                </li>
                              </ul>
                            </div>
                          }    
                        </div>
                      </div>
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item ">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#" 
                                onClick={() => this.toggleSingleAcc("calculators")}
                                className="heading-text__link">Calculators</a>
                            </div>
                            { !this.state.showIndividualCalculatorsAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualCalculatorsAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showOtherGuidesAcc || this.state.showIndividualCalculatorsAcc) && 
                            <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/calculators/sme-buddy-business-insurance-calculator" className="products-list__link" target="_blank">SME Buddy Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/calculators/home-loan-emi-calculator" className="products-list__link" target="_blank">Home Loan EMI Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/calculators/bike-loan-emi-calculator" className="products-list__link" target="_blank">Bike Loan EMI Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/calculators/car-loan-emi-calculator" className="products-list__link" target="_blank">Car Loan EMI Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/calculators/hra-exemption-calculator" className="products-list__link" target="_blank">HRA Exemption Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/finance/sukanya-samriddhi-yojana/sukanya-samriddhi-yojana-calculator" className="products-list__link" target="_blank">Sukanya Samriddhi Yojana Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/calculators/sip-calculator" className="products-list__link" target="_blank">SIP Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/calculators/rd-calculator" className="products-list__link" target="_blank">RD Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/calculators/swp-calculator" className="products-list__link" target="_blank">SWP Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/gst/online-gst-calculator" className="products-list__link" target="_blank">GST Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/finance/ppf/ppf-calculator" className="products-list__link" target="_blank">PPF Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/calculators/personal-loan-emi-calculator" className="products-list__link" target="_blank">Personal Loan EMI Calculator</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/calculators/emi-calculator" className="products-list__link" target="_blank">EMI Calculator</a>
                                </li>
                              </ul>
                            </div>
                          }    
                        </div>
                      </div>
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item ">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#" 
                                onClick={() => this.toggleSingleAcc("financeguides")}
                                className="heading-text__link">Finance Guides</a>
                            </div>
                            { !this.state.showIndividualFinanceGuidesAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualFinanceGuidesAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showOtherGuidesAcc || this.state.showIndividualFinanceGuidesAcc) && 
                            <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/finance/credit-score" className="products-list__link" target="_blank">What is Credit Score</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/finance/ppf" className="products-list__link" target="_blank">Public Provident Fund</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/finance/ppf/how-to-open-ppf-account" className="products-list__link" target="_blank">How to Open PPF Account?</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/finance/sukanya-samriddhi-yojana/how-to-open-sukanya-samriddhi-yojana-account" className="products-list__link" target="_blank">How to open Sukanya Samriddhi Account</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/gst" className="products-list__link" target="_blank">What is GST?</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/gst/gst-registration" className="products-list__link" target="_blank">GST Registration in India</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/pan-card" className="products-list__link" target="_blank">PAN Card</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/aadhaar-card" className="products-list__link" target="_blank">Aadhaar Card</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/property-tax" className="products-list__link" target="_blank">Property Tax</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/road-tax" className="products-list__link" target="_blank">Road Tax</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/passport/process" className="products-list__link" target="_blank">Passport Process</a>
                                </li>
                              </ul>
                            </div>
                          } 
                        </div>
                      </div>
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item ">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#" 
                                onClick={() => this.toggleSingleAcc("trafficrules")}
                                className="heading-text__link">Traffic Rules</a>
                            </div>
                            { !this.state.showIndividualTrafficRulesAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualTrafficRulesAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showOtherGuidesAcc || this.state.showIndividualTrafficRulesAcc) &&
                             <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/traffic-rules/puc-certificate" className="products-list__link" target="_blank">PUC Certificate</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/traffic-rules/vehicle-registration-certificate" className="products-list__link" target="_blank">Vehicle Registration Certificate</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/traffic-rules/revised-traffic-violation-fines-2019" className="products-list__link" target="_blank">New Traffic Fines</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/traffic-rules/fine-for-driving-without-helmet" className="products-list__link" target="_blank">Fine for Driving without Helmet</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/traffic-rules/types-of-driving-licence-in-india" className="products-list__link" target="_blank">Types of Driving Licence in India</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/traffic-rules/how-to-get-driving-licence-in-delhi" className="products-list__link" target="_blank">Driving Licence in Delhi</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/traffic-rules/how-to-get-driving-licence-in-bangalore" className="products-list__link" target="_blank">Driving Licence in Bangalore</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/traffic-rules/how-to-get-driving-licence-in-jaipur" className="products-list__link" target="_blank">Driving Licence in Jaipur</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/traffic-rules/bangalore-traffic-fines" className="products-list__link" target="_blank">Bangalore Traffic Fines</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/traffic-rules/pune-rto-fine" className="products-list__link" target="_blank">Pune RTO Fine</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/own-damage-premium" className="products-list__link" target="_blank">Own Damage Insurance</a>
                                </li>
                              </ul>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    { !this.state.showOtherGuidesAcc &&
                      <span onClick={() => this.toggleAcc("otherguides")}  className="material-icons sm-arrow-icon sm-arrow-d-icon">expand_more</span>
                    }
                    { this.state.showOtherGuidesAcc &&
                      <span onClick={() => this.toggleAcc("otherguides")}  className="material-icons sm-arrow-icon sm-arrow-d-icon">expand_less</span>
                    }
                  </div>
                </div>
                <div className="row row-main">
                  <div className="col-sm-10 offset-sm-1 row-main-list">
                    <div className="row">
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item ">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#" 
                                onClick={() => this.toggleSingleAcc("incometaxguides")}
                                className="heading-text__link">Income Tax Guides</a>
                            </div>
                            { !this.state.showIndividualIncomeTaxGuidesAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualIncomeTaxGuidesAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showIncomeTaxGuidesAcc || this.state.showIndividualIncomeTaxGuidesAcc) &&
                            <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/income-tax/how-to-save-income-tax-in-india" className="products-list__link" target="_blank">How to Save Income Tax in India</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/income-tax/income-tax-slabs-in-india" className="products-list__link" target="_blank">Income Tax Slab</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/income-tax/deduction-under-section-80c" className="products-list__link" target="_blank">Tax Deductions under Section 80C</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/income-tax/income-tax-slab-for-salaried-person" className="products-list__link" target="_blank">Individual Income Tax Slab</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/income-tax/income-tax-slab-for-women" className="products-list__link" target="_blank">Income Tax Slab for Women</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/income-tax/income-tax-slab-for-senior-citizen" className="products-list__link" target="_blank">Senior Citizen Tax Slab</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/income-tax/income-tax-benefits-for-senior-citizens" className="products-list__link" target="_blank">Income Tax Benefits for Senior Citizens</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/income-tax/tax-saving-investment-options-other-than-80c" className="products-list__link" target="_blank">Tax Saving Options other than 80C</a>
                                </li>
                              </ul>
                            </div>
                          }  
                        </div>
                      </div>
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item ">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#" 
                                onClick={() => this.toggleSingleAcc("rtooffices")}
                                className="heading-text__link">RTO Offices in India</a>
                            </div>
                            { !this.state.showIndividualRTOOfficeAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualRTOOfficeAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showIncomeTaxGuidesAcc || this.state.showIndividualRTOOfficeAcc) &&
                            <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/rto-office" className="products-list__link" target="_blank">RTO Office</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/rto-office/rto-pune" className="products-list__link" target="_blank">RTO Pune</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/rto-office/rto-ahmedabad" className="products-list__link" target="_blank">RTO Ahmedabad</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/rto-office/rto-bangalore" className="products-list__link" target="_blank">RTO Bangalore</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/rto-office/rto-mumbai" className="products-list__link" target="_blank">RTO Mumbai</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/rto-office/rto-delhi" className="products-list__link" target="_blank">RTO Delhi</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/rto-office/rto-lucknow" className="products-list__link" target="_blank">RTO Lucknow</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/rto-office/rto-thane" className="products-list__link" target="_blank">RTO Thane</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/rto-office/rto-chennai" className="products-list__link" target="_blank">RTO Chennai</a>
                                </li>
                              </ul>
                            </div>
                          }  
                        </div>
                      </div>
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item ">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#"
                                onClick={() => this.toggleSingleAcc("importantlinks")} 
                                className="heading-text__link">Important Links</a>
                            </div>
                            { !this.state.showIndividualImpLinksAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualImpLinksAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showIncomeTaxGuidesAcc || this.state.showIndividualImpLinksAcc) &&
                            <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/reviews" className="products-list__link" target="_blank">Eezeeinsure Insurance Reviews</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/reviews/digit-car-insurance-reviews" className="products-list__link" target="_blank">Car Insurance Reviews</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/reviews/digit-two-wheeler-insurance-reviews" className="products-list__link" target="_blank">Bike Insurance Reviews</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/reviews/digit-commercial-vehicle-insurance-reviews" className="products-list__link" target="_blank">Commercial Vehicle Insurance Reviews</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/reviews/digit-travel-insurance-reviews" className="products-list__link" target="_blank">Travel Insurance Reviews</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/reviews/digit-health-insurance-reviews" className="products-list__link" target="_blank">Health Insurance Reviews</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/reviews/digit-mobile-insurance-reviews" className="products-list__link" target="_blank">Mobile Insurance Reviews</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/become-an-agent" className="products-list__link" target="_blank">Become a Digit Partner</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/become-an-agent/how-to-become-insurance-agent" className="products-list__link" target="_blank">General Insurance Agent</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/become-an-agent/how-to-become-a-health-insurance-agent" className="products-list__link" target="_blank">Health Insurance Agent</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/become-an-agent/how-to-become-a-motor-insurance-agent" className="products-list__link" target="_blank">Motor Insurance Agent</a>
                                </li>
                              </ul>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-sm-3 pl-0 pr-0">
                        <div className="products-list-item ">
                          <div className="heading-text-wrap">
                            <div className="heading-text">
                              <a href="#" 
                                onClick={() => this.toggleSingleAcc("models")}
                                className="heading-text__link">Car Brands &amp; Models</a>
                            </div>
                            { !this.state.showIndividualModelsAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_more</span>
                            }
                            { this.state.showIndividualModelsAcc && 
                              <span className="material-icons sm-arrow-icon sm-arrow-m-icon">expand_less</span>
                            }
                          </div>
                          { (this.state.showIncomeTaxGuidesAcc || this.state.showIndividualModelsAcc) &&
                            <div className="products-list-wrap">
                              <ul className="products-list">
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/brand/maruti-suzuki" className="products-list__link" target="_blank">Maruti Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/brand/toyota" className="products-list__link" target="_blank">Toyota Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/brand/tata-motors" className="products-list__link" target="_blank">Tata Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/brand/hyundai" className="products-list__link" target="_blank">Hyundai Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/brand/kia" className="products-list__link" target="_blank">Kia Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/brand/mahindra" className="products-list__link" target="_blank">Mahindra Car Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/brand/tata-motors/tiago" className="products-list__link" target="_blank">Tata Tiago Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/brand/tata-motors/nexon" className="products-list__link" target="_blank">Tata Nexon Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/brand/hyundai/i20" className="products-list__link" target="_blank">Hyundai i20 Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/brand/hyundai/creta" className="products-list__link" target="_blank">Creta Insurance</a>
                                </li>
                                <li className="products-list__item">
                                  <a href="/motor-insurance/car-insurance/brand/maruti-suzuki/baleno" className="products-list__link" target="_blank">Baleno Insurance</a>
                                </li>
                              </ul>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    { !this.state.showIncomeTaxGuidesAcc &&
                      <span onClick={() => this.toggleAcc("incometaxguides")}  className="material-icons sm-arrow-icon sm-arrow-d-icon">expand_more</span>
                    }
                    { this.state.showIncomeTaxGuidesAcc &&
                      <span onClick={() => this.toggleAcc("incometaxguides")}  className="material-icons sm-arrow-icon sm-arrow-d-icon">expand_less</span>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}