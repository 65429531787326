import React from 'react';
import './header.scss';

export class SupportMenu extends React.Component {

  render() {
    return (
      <div className="sign-in-dropdown">
        <a href="contactus" className="sign-in-link">Contact Us</a>
        <div className="need-help-text-wrap">
          <div className="need-help-text"></div>
          <a href="tel:9915070204" target="_blank" className="contact-mail">
            <span className="material-icons support-icons ">call </span>
            <span>+91 99870 80204</span>
          </a>
          <a href="https://api.whatsapp.com/send?l=en&amp;text=Hi&amp;phone=919915070204" target="_blank" className="contact-mail">
            <span className="material-icons support-icons whatsappIcon">whatsapp </span>
            <span>+91 99870 80204</span>
          </a>
          <a href="mailto:support@eezeeinsure.com" target="_blank" className="contact-mail">
            <span className="material-icons support-icons ">mail_outline </span>
            <span>support@eezeeinsure.com</span>
          </a>
        </div>
      </div>
    );
  }

}