import React from 'react';
import './AboutUs.scss';
import AboutBanner from '../../assets/images/diverse-people-working-office.jpg';

export class AboutUs extends React.Component<{}, {}> {

  render() {
    return (
      <>
        <section className="car-wrapper hero-unit">
          <div className="page-content">
            <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="cr-content">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                      <div className="cr-header">
                        <h1 className="cr-header">About Us</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-pad">
          <div className="container">
            <div className="row">
              <div className='col-12'>
                <div className="contact_banner_img">
                  <img className="choose-card__img img-responsive" alt="Contact" src={AboutBanner} />
                </div>
              </div>
              <div className="col-md-12">
                <div>
                  <div className="content-info-list team-list">
                    <h3 className="font-bold">OUR DIRECTORS</h3>
                    <hr></hr>
                    <strong>Harsimran Kaur</strong>
                    <p>Harsimran has a professional experience into planning and execution. She is one out of two main pillars of the company. Harsimran has industry experience of 15 years, she has worked as senior management in the corporate sector. Harsimran has impressive core skills, which includes - marketing, corporate event planning and corporate communications.</p>
                    <p>Harsimran's educational background has a great impact on her profile, post her MBA in International Marketing from the University of Business School, Chandigarh - Harsimran redeemed herself with India’s leading Corporate Associations. Her strengths is multi-tasking across different departments such as senior relationship manager with corporate/government sectors.</p>
                    <hr></hr>
                    <strong>Simranjeet Singh</strong>
                    <p>Simranjeet has a rich experience of 25 years in corporate financial services, He has played management leadership roles with Tata Steel, HDFC Bank, Reliance General Insurance and Yes Bank. He has earned a massive skillset with his hard work. He has worked as relationship manager, customer service orientator and multi-locational team manager.</p>
                    <p>His amiable nature and disciplinary work style has elevated his corporate career. He aspires to build an insurance distribution network in India to provide “doorstep services” to retail and SME customers for general insurance products.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

}