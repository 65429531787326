import React from 'react';
import './PrivacyPolicy.scss';

export class PrivacyPolicy extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">PRIVACY POLICY</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">INTRODUCTION:</h3>
                      <div>The Privacy Policy covers the practices for handling and securing your Personal Information by                         (Owner’s of brand name and style Eezeeinsure) and its website (www.eezeeinsure.com)
This Privacy Policy is applicable to visitor/customer/users/persons that use any product or services from www.eezeeinsure.com, mobile site, mobile app & offline channels including call centers and other offices. 
As visitor/customer/user/persons of www.eezeeinsure.com, the person agrees with this Privacy Policy and the terms and conditions mentioned herein.
This privacy statement does not apply to the websites, mobile sites and mobile apps of our other third parties, even if their websites are linked to www.eezeeinsure.com. We recommend you review the privacy statements of the other parties with whom you interact.
                      </div>  
      <p>&nbsp;</p>
      <h3 className="font-bold">WHAT PERSONAL INFORMATION WE COLLECT FROM YOU AND HOW WE USE IT?</h3>
                      <div>Personal Information’ means and includes all information that can be linked to a specific individual or to identify any individual, such as name, address, mailing address, telephone number, email address, and any and all details that may be necessary from the user.
When you visit www.eezeeinsure.com website or its sub domain’s, we may collect information regarding the domain and host from which you access the internet, the internet protocol address of the computer or internet service provider you are using, and anonymous statistical data. The website and mobile site uses cookie and tracking technology depending on the features offered. Personal Information will not be collected via cookies and other tracking technology; however, if you previously provided personally identifiable information, cookies may be tied to such information.
When browsing our Website, you are not required to provide any Personal Information unless and until you choose to make a sign up or contact us 
</div>  
<p>&nbsp;</p>
      <h3 className="font-bold">SURVEYS:</h3>
                      <div>www.eezeeinsure.com values opinions and comments from members, so we frequently conduct surveys, both online and offline. Participation in these surveys is entirely optional. Typically, the information is aggregated, and used to make improvements to website, mobile site and mobile app and its services and to develop appealing content, features and promotions for members. Survey participants are anonymous unless otherwise stated in the survey.</div> 

                      <p>&nbsp;</p>
      <h3 className="font-bold">COOKIES</h3>
                      <div>Cookies are small pieces of information that are stored by your browser on your device's hard drive. Cookies are only read by the server that placed them, and are unable to do such things as run programs on your computer, plant viruses or harvest your Personal Information. The use of cookies is very common on the Internet.
www.settleezee.com use of cookies is similar to that of any other reputable online companies. No personally identifiable information (PII) about you (e.g., name, address, etc.) is gathered or stored in the cookies placed by a website or mobile site and, as a result, none can be passed on to any third parties. Cookies allow us to serve you better and more efficiently, and to personalize your experience at our website and mobile site. www.settleezee.com uses cookies to personalize your experience on the website and mobile site, and with respect to advertisements.
</div> 


                      <p>&nbsp;</p>
      <h3 className="font-bold">OTHERS:</h3>
                      <div>From time to time we may add or enhance services available on the Website. To the extent these services are provided, and used by you, we will use the information you provide to facilitate the service requested. For example, if you email us with a question, we will use your email address, name, nature of the question, etc. to respond to your question. We may also store such information to assist us in making the Website the better and easier to use.
We review our Privacy Policy from time to time, and we may make periodic changes to the policy in connection with that review. Therefore, you may wish to bookmark this page and/or periodically review this page to make sure you have the latest version.
You may always submit concerns regarding this Privacy Policy via email to info@eezeeinsure.com and  we will respond to all reasonable concerns or inquiries.
Thank you for using the Website!
© EEZEEINSURE www.eezeeinsure.com. All Rights Reserved.
</div> 
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}