import React from 'react';
import './MarineInsurance.scss';

export class MarineInsurance extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Marine Insurance</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-sm-center mb-30">
                    <h2 className="h2-v2 text-left">Marine Insurance: Buy/Renew Marine Insurance Policy Online Instantly</h2>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">What is Marine Insurance?</h3>
                      <div>
                        Marine Insurance provides comprehensive protection to goods as soon as they leave the factory or warehouse until they finally arrive at their destination -   the transportation can be on land or across sea or by air.  
                        Marine Insurance helps companies to mitigate risks of transportation  of raw materials / work in progress or finished good through their supply and distribution networks
                      </div>
                      <p>&nbsp;</p>
                      <h3>Why do we need Marine Insurance?</h3>
                      <div>
                        Marine Insurance protects the insured's goods against perils and risks involved in transit.  So it helps in protecting the insured's finances and assets while they are being transported via sea / land or by air 
                        <ul>
                          <li>With 3-4 times growth in shipment of goods through ships -  the risks of accidents has also increased significantly - hence we must take Marine Insurance to provide financial protection against loss / damage of goods during transit</li>
                          <li>In case of unforeseen Marine disaster like oil spill or polluting of seas / land with goods or chemicals being trnsported - the cost of litigation and/or penalties can be prohibitively high and this can be offset by purchasing Marine Insurance cover
</li>
                          <li>Increase in cargo theft over last 2 decades has increased the risk for loss of goods
</li>
                          <li>Mandatory Legal requirement in some countries
</li>
                          <li>Increased risk due to  natural disasters
</li>
                          <li>Significant Risk in loss of Containers at Sea in last 10 years
</li>
                        </ul>
                      </div>
                    <p>&nbsp;</p>
                      <h3>What is the nature and scope of Marine Insurance?</h3>
                      <div>
                        Marine Insurance Act defines the concept of Marine Insurance as   - protection that covers against all man-made calamities or perils which include theft, robbery, piracy, arson, etc. 
              Marine Insurance also covers for natural calamities such as earthquakes, lightning, cyclones,
                      </div>
                      <p>&nbsp;</p>
                      <h3>What does Marine Sales Turnover Policy cover?</h3>
                      <div>
                        Marine Sales Turnover Policy  - it is an open policy ususually offered upto 1 year and provides Insurance cover for:
                        <ul>
                          <li>Imports + Customs Duty (Actual Or Contingent)  and</li>
                          <li> Domestic Purchase Of Raw Materials, Consumables & Stores items and</li>
                          <li>Unlimited Inter- Factory / Inter-Depot / To & Fro Job Worker Movements and</li>
                          <li>Exports (FOB/CIF) and</li>
                          <li>Domestic Finished Goods Sales and</li>
                          <li>Temporary Storage Cover At Intermediate Locations Like Job Workers / C & F Premises</li>
                        </ul>  
                      </div>
                      <p>&nbsp;</p>
                      <h3>Types of Marine Insurance Policies</h3>
                      <div>
                        Marine Cargo Insurance covers the loss or damages caused to Marine cargo during the transit.  Some types of Marine Insurance include:
                        <ul>
                          <li>Liability Insurance</li>
                          <li>Ship / Hull Insurance</li>
                          <li>Freight Insurance</li>

                        </ul>
                      </div>
                      <p>&nbsp;</p>
                      <h3>What are the nine key elements of Marine Insurance Contract?</h3>
                      <ul>
                        <li>Features of General Contract</li>
                        <li>Insurable Interest</li>
                        <li>Utmost Good Faith</li>
                        <li>The Doctrine of Indemnity</li>
                        <li>Subrogation</li>
                        <li>Warranties</li>
                        <li>Proximate Cause</li>
                        <li>Assignment and Nomination of the policy</li>
                        <li>Return of Premium</li>
                      </ul>
      <p>&nbsp;</p>
                      <h3>What are exclusions in Marine Insurance?</h3>
                      <div>
                        Marine Insurance Policies have the following exclusions:
                        <ul>
                          <li>Wilful Conduct of the insured</li>
                          <li>Loss caused due to delay of cargo</li>
                          <li>Insufficient Package</li>
                          <li>War and Strikes</li>
                          <li>Loss /damage due to financial default or insolvency of the shipping co. owner</li>
                          <li>Ordinary Leakage / Loss of weight or Volume</li>
                        </ul>
                      </div>
                      <p>&nbsp;</p>
                      <h3>FAQ</h3>
                      <div className='font-bold'>Marine Insurance cover is available for specific situations. What are they?</div>
                      <div>
                        The situations under which Marine Insurance cover is applicable are:
                        <ul>
                          <li>Fire or Blast  / Sinking  / Stranding</li>
                          <li>Cargo release at port of disturbance</li>
                          <li> Washing Overboard</li>
                          <li>Average Sacrifice Recovery Charges</li>
                          <li>Collission, overturning of land transport</li>
                          <li>Earthquake and Lightening</li>
                          <li>Total loss of package lost overboard</li>
                        </ul>
              
                      </div>
                      <br/>
                      <div className='font-bold'>What are the few benefits offered by Marine Sales Turnover Policy?</div>
                      <div>
                        Some benefits offered are:
                        <ul>
                          <li>Overall Lower premium (based on actual sales turnover) when compared to individual shipment Marine policies</li>
                          <li>Overall coverage across raw materials from sourcing point to finished goods at destination point seamlessly</li>
                          <li>Premium can be made quarterly /half-yearly also</li>
                          <li>Periodical submission of movement of goods not needed -  only actual monthly sales numbers  /values are needed</li>
                          <li>Online issuance of Marine Insurance certificates</li>
                          <li>Add -on options include intermediate storage cover</li>
                        </ul>
                      </div>
                      <br/>
                      <div className='font-bold'>What are the standard documents needed while lodging Marine Insurance claim?</div>
                      <div>
                        Typically the following documents are needed to settle Marine Insurance claims:
                        <ul>
                          <li>Original Marine Insurance Policy or Certificate of Marine Insurance </li>
                          <li>Original Shipping Invoices copies alongwith Packing List, and / or Weightment notes </li>
                          <li>Original Bill of Lading and / or other contract of  transportation / carriage</li>
                          <li>Survey report and other documentary evidence to prove the extent of loss or damage</li>
                          <li>Landing Remarks/ Account and Weightment notes at final destination</li>
                          <li>Correspondence / Letters exchanged with Transportation/Shipping Carriers and other Parties regarding their liability for loss or damage suffered during transit</li>
                        </ul>
                      </div>
                      <br/>
                      <div className='font-bold'>What are 4 types of Marine Insurance contract freight terms?</div>
                      <div>
                        FOB  - Free on Board
              FOR -  Free on Rail
              C&amp;F -  Cost and Freight
              CIF -  Cost Insurance &amp; Freight
                      </div>
                      <br/>
                      <div className='font-bold'>What is covered under Institute Clauses Cover?</div>
                      <div>
                        Marine Insurance coverage available under the standard clauses include:
                        <ul>
                          <li>Constructive Total Loss</li>
                          <li>Actual Total Loss</li>
                          <li>Particular Average (partial loss by insured peril)</li>
                          <li>General Average</li>
                          <li>Collision Liability</li>
                          <li>Specified Expenses such as (Reconditioning Costs / Survey Fees  / Forwarding Expenses /  Sue and Labour etc)</li>
                        </ul>
                      </div>
                      <br/>
                      <div className='font-bold'>How is Marine Insurance Hull Premium calculated?</div>
                      <div>
                        Various factors contribute towards the calculation of Marine Hull Insurance Premium.  These are:
                        <ul>
                          <li>Type of Insurance cover needed</li>
                          <li>Age of the ship / vessel</li>
                          <li>Type of Ship/Vessel</li>
                          <li>Management and Ownership terms of the ship</li>
                          <li>Ship Cost/Valuation</li>
                          <li>Trading and Tonnage limits of the ship</li>
                        </ul>

                      </div>
<br/>
                      <div className='font-bold'>Who can have Ïnsurable Interest' in Marine Insurance</div>
                      <div>
                        Insurable Interest under Marine Insurance policy is deemed to be applicable to the:
                        <ul>
                          <li> Owner of the ship /vessel</li>
                          <li> Owner of the Cargo</li>
                          <li> Master or Crew of the Ship</li>
                          <li>Creator who has lent money on the security of the ship</li>
                          <li>Mortgager in case the insurable subject has been mortgaged</li>
                          <li>Trustee with propery in trust</li>
                          <li>Person advancing the freight</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}