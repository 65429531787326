import React from 'react';
import './EmailSuccessModal.scss';

export class EmailSuccessModal extends React.Component<
    {showModalDialogP: boolean, closeModalDialog: any}, 
    {  }> {

  constructor(props: any) {
    super(props);
  }



  render() {

    var closeModalDialog = this.props.closeModalDialog;
    return (
      <div 
        className={
          "modal-success-email " +
          (this.props.showModalDialogP ? "active " : "inactive")
      }>
        <div className='modal-email-success-content'>
          <div className='email-heading'>Email sent successfully.</div>
          <div className="ei-primary-button emailclosebtn">
          <button 
            type="button"
            className='primary-button'
            onClick={() => closeModalDialog()}>
              <span>Ok</span>
          </button>
          </div>
        </div>
      </div>
    )
  }

}