import React from 'react';
import './TractorInsurance.scss';

export class TractorInsurance extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Tractor Insurance</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-sm-center mb-30">
                    <h2 className="h2-v2">Tractor Insurance: Buy/Renew Tractor Insurance Policy Online Instantly</h2>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">What is Tractor Insurance?</h3>
                      <p></p>
                      <h3>What insurance covers are available in Tractor Insurance?</h3>
        <div>
          Tractor Insurance provides covers against different types of external damages:
          <ul>
            <li>It covers for manmade causes like burglary, theft, accidental external damage etc. </li>
            <li>Tractor insurance also provides cover against natural causes like Flood, storm, lightning, earthquake, landslide etc. It also includes cover for third-party liabilities to any injury, death, property damage etc.
</li>
            <li>There is an option available to insure paid driver &amp; electrical accessories</li>
          </ul>


        </div>
        <p>&nbsp;</p>
        <h3>What is Tractor Insurance?</h3>
        <div>Tractor Insurance is an Insurance policy for farmers that protects them against property damage if something happens to their tractor or other farm equipment. Tractor Insurance is offered as commercial vehicle policy by insurers in India. Tractor Insurance provides complete and all round protection to your tractor against any unforeseen damages or loss due to any accident casued by natural disaster, theft, fire or accident.</div>
        <p>&nbsp;</p>
        <h3>What are the exclusions in Tractor Insurance polocy?</h3>
        <div>The following situations are not covered in Tractor Insurance policy:
            <ul>
              <li>Common wear and tear and/or general aging of the vehicle</li>
              <li>Consequential loss or any depreciation shall not be covered.</li>
              <li>Any mechanical or electrical breakdown shall not be covered.</li>
              <li>The use of the vehicle beyond its limitation shall not render liability for insurance cover</li>
              <li>Any loss is occurring as a result of damage to or by a person driving under the influence of narcotics or liquor</li>
              <li>Any damage as a result of mutiny, nuclear exposure or war.</li>
            </ul>
        </div>
        <p>&nbsp;</p>

        <h3>Do tractors have insurance?</h3>
        <div>Tractors and other agricultural vehicles used by customer on his/her own land do not need insurance.                                You don't have to get comprehensive cover for your tractor. Still, any level of protection can offer a sense of security.</div>
        <p>&nbsp;</p>
        <h3>How is premium for Tractor Insurance calculated?</h3>
        <div>Tractor Insurance Premium= Own Damage – (NCB + Discount) + Liability premium that is fixed by the IRDAI.</div>
        <p>&nbsp;</p>
        <h3>What are the documents needed for lodging Tractor Insurance Claim?</h3>
        <div>The insured must produce the following documents to lodge a Tractor Insurance Claim:
            <ul>
              <li>Duly signed claim form.</li>
              <li>Copy of your tractor's Registration Certificate (RC)</li>
              <li>Copy of your driving license.</li>
              <li> Photographs of the damaged tractor.</li>
              <li>Copy of police FIR (in case of third party liability claims)</li>
              <li>Original estimate of loss.</li>
            </ul>
        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}