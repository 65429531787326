import React from 'react';
import './ContactUs.scss';
import axios from "axios";
import ContactBanner from '../../assets/images/contact-banner.png';
import { EmailSuccessModal } from '../emailsuccessmodal/EmailSuccessModal';

export class ContactUs extends React.Component<{}, { message: string, 
    isLoading: boolean,
    showModalDialog: boolean,
    services: string, phoneNumber: '', firstName: '' }> {

  constructor(props: any) {
    super(props);
    this.state = {
      message: '',
      services: '',
      isLoading: false,
      showModalDialog: false,
      phoneNumber: '',
      firstName: ''
    };
    this.handleSendEmail = this.handleSendEmail.bind(this);
    this.closeModalDialog = this.closeModalDialog.bind(this);
  }

  handleMessageChange = (event: any) => {
    this.setState({message: event.target.value});
  }

  handleServicesChange = (event: any) => {
    this.setState({services: event.target.value});
  }

  handlePhoneNumberChange = (event: any) => {
    this.setState({phoneNumber: event.target.value});
  }

  handleFirstNameChange = (event: any) => {
    this.setState({firstName: event.target.value});
  }

  handleSendEmail() {
    let message = this.state.message;
    let servicesValue = this.state.services;
    let phoneNumber = this.state.phoneNumber;
    let firstName = this.state.firstName;
    this.setState({isLoading: true});
    let baseURL = 'https://eezeeinsure.com/njsapp1/sendemail/';
    axios.post(baseURL, {
      message: message,
      subject: 'Eezeeinsure support contact',
      service: servicesValue,
      phone: phoneNumber,
      firstName: firstName
    }).then((response) => {
      this.setState({isLoading: false});
      this.setState({showModalDialog: true});
    }).catch(error => {
      this.setState({isLoading: false});
      this.setState({showModalDialog: true});
    });
    return false;
  }

  closeModalDialog() {
    this.setState({showModalDialog: false});
  }

  render() {
    return (
      <>
        <section className="car-wrapper hero-unit">
          <div className="page-content">
            <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="cr-content">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                      <div className="cr-header">
                        <h1 className="cr-header">Contact Us</h1>
                      </div>
                      <div className="car-register-num-content-image skeleton">
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-pad">
        <EmailSuccessModal showModalDialogP={this.state.showModalDialog}
          closeModalDialog={this.closeModalDialog}/>
          <div className="container">
            <div className="contact-us-page">
              <div className="container">
                <div className="row">
                  <div className='col-12'>
                    {/* <h2 className="heading">REACH US</h2> */}
                    <div className="contact_banner_img">
                    <img className="choose-card__img img-responsive" alt="Contact" src={ContactBanner} />
                    </div>
                    <div className="description">Please fill out the form and send enquiry. We will get back<br /> to you as soon as possible.</div>
                    <div className="c-wrapper">
                      <div className="info-1">
                        <div className="font-bold">Address</div>
                        <div>Eezeeinsure<br />
                          204, Sector 21 A<br />
                          Chandigarh 160022<br />
                          +91 99870 80204</div>
                        <div className="font-bold mt-2 mobile-space">Follow Us:</div>
                        <ul className="cnss-social-icon ">
                          <li className="cn-fa-facebook cn-fa-icon " style={{ display: "inline-block" }}>
                            <a className="cnss-facebook-f" target="_blank" href="#" title="Facebook"
                              style={{ width: "37px", height: "37px", padding: "6px 0", margin: "3px", color: "#ffffff", borderRadius: "50%" }}>
                              <i title="Facebook" style={{ fontSize: "25px" }} className="fa fa-facebook"></i></a>
                          </li>
                          <li className="cn-fa-twitter cn-fa-icon " style={{ display: "inline-block" }}>
                            <a className="cnss-twitter" target="_blank" href="#" title="Twitter"
                              style={{ width: "37px", height: "37px", padding: "6px 0", margin: "3px", color: "#ffffff", borderRadius: "50%" }}>
                              <i title="Twitter" style={{ fontSize: "25px" }} className="fa fa-twitter"></i></a>
                          </li>
                          <li className="cn-fa-instagram cn-fa-icon "
                            style={{ display: "inline-block" }}><a className="cnss-instagram" target="_blank"
                              href="#" title="Instagram"
                              style={{ width: "37px", height: "37px", padding: "6px 0", margin: "3px", color: "#ffffff", borderRadius: "50%" }}>
                              <i title="Instagram" style={{ fontSize: "25px" }} className="fa fa-instagram"></i></a>
                          </li>
                          <li className="cn-fa-linkedin cn-fa-icon " style={{ display: "inline-block" }}>
                            <a className="cnss-linkedin" target="_blank" href="#"
                              title="LinkedIn" style={{ width: "37px", height: "37px", padding: "6px 0", margin: "3px", color: "#ffffff", borderRadius: "50%" }}>
                              <i title="LinkedIn" style={{ fontSize: "25px" }} className="fa fa-linkedin"></i></a>
                          </li>
                        </ul>
                      </div>
                      <div className="info-2">
                        <div className="contact-us-div">
                          <div className="contact-us-form">
                            <form>
                              <div className="contact-us-form-div">
                                <div className='contact-us-form-div-inner1'>
                                  <select className="select-services"
                                    value={this.state.services}
                                    onChange={this.handleServicesChange}>
                                    <option>Services</option>
                                    <option>Health Insurance</option>
                                    <option>General Insurance</option>
                                  </select>
                                  <input id="phone" name="phone" className="input-text" placeholder="Phone Number" type="text"
                                    value={this.state.phoneNumber} 
                                    onChange={this.handlePhoneNumberChange} />
                                  <input id="firstName" name="firstName" className="input-text" placeholder="First Name" type="text" 
                                    value={this.state.firstName}
                                    onChange={this.handleFirstNameChange} />
                                </div>
                                <div className='contact-us-form-div-inner2'>
                                  <div>
                                    <textarea name="textarea-569" cols={40} rows={10} className="input-text"
                                      style={{ height: "112px" }}
                                      value={this.state.message} onChange={this.handleMessageChange}
                                      aria-required="true" aria-invalid="false" placeholder="Message"></textarea>
                                  </div>
                                  <div className="ei-primary-button">
                                    <button 
                                      type="button"
                                      className={this.state.isLoading ? 'primary-button send-btn button--loading': 'primary-button send-btn'}
                                      disabled={this.state.isLoading}
                                      onClick={this.handleSendEmail}>
                                        {!this.state.isLoading ? 
                                          <span>SEND NOW</span> : <span> </span>
                                        }
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

}