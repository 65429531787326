import React from 'react';
import './GCVInsurance.scss';

export class GCVInsurance extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Goods Carrying Vehicle Insurance</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-sm-center mb-30">
                    <h2 className="h2-v2 text-left">Goods Carrying Vehicle Insurance: Buy/Renew Goods Carrying Vehicle Insurance Policy Online Instantly</h2>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">What is Goods Carrying Vehicle Insurance?</h3>
                      <p></p>
    <div>
    GCV Insurance protects the policy holder for damage to  their Goods Carrying Vehicle and its accessories (such as trucks / lorry / tempo / trailers etc) due to accident or collisions, fire, earthquake, flood, storm, explosion, self-ignition or lightning, riots, malicious act or theft.
    </div>

    <p>&nbsp;</p>
    <h3>What are the insurance covers available in a GCV Insurance Policy?</h3>
    <div>
      A comprehensive GCV Insurance Policy has the following covers:
      <ul>
        <li>Damages caused to the truck /lorry / 3 wheeler GCV in case of an accident
        </li>
        <li>Loss due to natural calamities like Floods /  Storm /  Lightning
        </li>
        <li>Damage or Loss caused due to Fire
        </li>
        <li>Loss due to Theft
        </li>
        <li>Personal Accident cover for GCV vehicle driver
        </li>
        <li>Loss to third party life or property
        </li>
      </ul>
    </div>
    <p>&nbsp;</p>
    <h3>What are different factors impacting the GCV Premium?</h3>
    <div>
      <p>Different factors determing the GCV Premium are:</p>
      <ul>
        <li>GCV Make, Model, Year of Manufacture</li>
        <li>Nature of Goods being carried by GCV</li>
        <li>Size (goods carrying capacity)</li>
        <li>Geographical Zone / Location of operations of the GCV</li>
        <li>No Claim Bonus (NCB) lowers the OD portion of the premium</li>
        <li>Only Third Party Premium are always lower than premium for comprehensive policies</li>
      </ul>
    </div>
    <p>&nbsp;</p>
    <h3>What are the exclusions under Goods Carrying Vehicle Insurance in India?</h3>
    <div>
      Typical exclusions in GCV policy are:
      <ul>
        <li>Consequential Loss or Damages (for eg - if the damaged GCV  is being used inappropriately and its engine gets damaged, then the loss will not be covered)</li>
        <li>Claims arising out of the contract</li>
        <li>Depreciation</li>
        <li>Wear and tear</li>
        <li>Mechanical or electrical breakdown</li>
        <li>Damages to tyres unless they are damaged at time of the accident</li>
        <li>Any damage that occurs if driver is under influence of liquor or drugs. Other exclusions will include all specified deductibles as mentioned in the policy
If only Third Party Policy is taken by insured - then the own damages caused to the vehicle are not covered.</li>
      </ul>
    </div>
      <p>&nbsp;</p>
    <h3>What is IMT 23 cover?</h3>
    <div>
      IMT 23 cover is for the vehicle's lamps / tyres / tubes / mudguards / tubes / bonnet /side part bumpers / headlights / paintwork of the damaged portion of the Goods Carrying Vehicle.
    </div>

    <p>&nbsp;</p>
    <h3>FAQs</h3>
    <div>

    </div>

    <h5 className='font-bold'>Which vehicles are used for carrying goods?</h5>
<p>Truck, also called lorry, or any motor vehicle (including tempos /  3 wheelers etc)  designed to carry freight or goods or to perform special services such as fire fighting.

Any transport vehicle whose unladen weight is more than 7,500 kg or is used for carrying goods is classified as  Goods Commercial Vehicle."
</p>

<h5 className='font-bold'>What is the maximum number of days a cover note is valid?</h5>
<p>A cover note is valid for a period of 60 days from the date of issue of the cover note and the insurance compmany will issue the Insurance Policy before the cover note expires.
</p>

<h5 className='font-bold'>What are various categories of GCV (Trucks) for which insurance covers are available?</h5>
<div>There are typically 4 categories of GCV (Trucks) plying in India
  <ul>
    <li>Mini Trucks - these are the smallest types of trucks available (in size and carrying capacity). Such GCV are mostly used for short distance commercial, hyperlocal and local pickups</li>
    <li>Light Trucks -  these GCV are used for transporting goods over cities (medium to large distances) for day-to-day inter city transaportation
    </li>
    <li>Medium Size GCV (Trucks) -  these are bigger in size and goods carrying capacity than the light trucks.  Such trucks are designed to transport larger volumes and also large-sized goods
    </li>
    <li>Off Road GCV (Trucks) - these are specially designed heavy duty trucks mainly used for specific uses in contruction /infrastructure industry and also include car towing trucks"
    </li>
  </ul>  
</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}