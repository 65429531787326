import React from 'react';
import './AutoRickshawInsurance.scss';

export class AutoRickshawInsurance extends React.Component<{}, { }> {

  render() {
    return (
        <>
          <section className="car-wrapper hero-unit">
            <div className="page-content">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="cr-content">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-7 car-register-num-content">
                        <div className="cr-header">
                          <h1 className="cr-header">Autorickshaw Insurance</h1>
                        </div>
                        <div className="car-register-num-content-image skeleton">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-pad">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-sm-center mb-30">
                    <h2 className="h2-v2 text-left">Autorickshaw Insurance: Buy/Renew Autorickshaw Insurance Policy Online Instantly</h2>
                  </div>
                </div>
                <div className="col-md-10 col-md-offset-1">
                  <div>
                    <div className="content-info-list">
                      <h3 className="font-bold">What is Autorickshaw Insurance?</h3>
                      <p></p>
                      <div>Autorickshaw insurance is a type of policy designed to protect and suit the needs of autorickshaw (3 wheeler Auto) in India. It’s mandatory for all auto owners to at least have a third-party auto rickshaw insurance to financially protect them from third-party liabilities. There is additionally a comprehensive auto rickshaw policy to cover for own damages that can be caused due to accidents, collisions, natural calamities, fires and other such mishaps</div>
                      <p>&nbsp;</p>
    <h3>What are two types of Autorickshaw Insurance available in India?</h3>

    <div>
    <div className='font-bold'>The two types of Autorickshaw  insurance are:</div>
    <ul>
      <li>Third Party (or Liability Only policy):   This covers only the injury or death of a third person and/or damage to  third person's - on account of any accident caused by insured's Autorickshaw.  Third Party Insurance also includes compulsory personal accident cover for the owner-driver.  This type of policy does not cover any damages to own Autorickshaw. 
As per Indian laws, it is mandatory for every Autorickshaw owner to take Third Party Cover.</li>
      <li>Comprehensive (or Package policy): To cover for loss/damage to your Autorickshaw - you must purchase Comprehensive Policy covering Third Party and Own Damage(OD) covers.  Own Damage covers any damage caused to own auto rickshaw</li>
    </ul>
</div>
      <p>&nbsp;</p>
    <h3>What are the various insurance covers available for Autorickshaw  insurance?</h3>
    Autorickshaw Comprehensive Policy covers damage to your Autorickshaw due to 
Accidents -  any damage caused to own Autorickshaw due to accident or collision with any other vehicle
Theft - it covers loss arising due to theft of your Autorickshaw or malicious acts
<br/><br/>
Third Party Damage -  damage caused to third party vehicle or property by your Autorickshaw
Natural calamities like floods, earthq​uake, fire and more
Man-made calamities like vandalism, riots and terror attacks
Damage due to Explosion / Hailstorm /Terrorism /Frost /Inundation / Self-ignition /Lightening /Cyclone / Tempest / Riot & Strikes / Hurricane  / Landlside / Rockslide /Typhoon /  Transit by Rail/Road/Air.
<p>&nbsp;</p>
      <h3>What are typical exclusions in Autorickshaw insurance?</h3>
      <div>Some typical exclusions in Autorickshaw insurance are  -
        In the case of a Third-Party Liability Only Policy, damages to own Autorickshaw will not be covered.
        <br/><br/>Consequential Damages  -  Any damage arising not directly due to an accident is not covered  -   for example if after an accident - the damaged Autorickshaw is being used incorrectly and as a result of this - the engine gets damaged, then such a loss will not be covered
        Driving without valid license or drunk driving -   any loss or damage that occurs if the Autorickshaw's owner-driver is drunk or driving without a valid licence is not covered under any Autorickshaw insurance policy
        <br/><br/>Contributory Negligence  -   Any damage or loss that occurs due to a contributory negligence of the owner-driver is excluded  - for example if the Autorickshaw is driven when there is an existing flood like situation is not covered.</div>
        <p>&nbsp;</p>
    <h3>FAQs</h3>
    <div className='font-bold'>What are the types of Autorickshaws for which Insurance covers are available?</div>
    
    <div>There are primarily two types of Autorickshaws for which Insurance covers are available -  
        <ul>
          <li>Petrol/Diesel Autorickshaw:  This is most popular mode of transportation in urban areas - for eg :  Bajaj / TVS  / Scooter India etc </li>
          <li>Electric Autorickshaw:  The electric or e-Autorickshaw is primarily run by electric motors/solar panels or batteries - these also have to be mandatorily insured</li>
        </ul>
    </div>
    <br/>
    <div className='font-bold'>What are different factors impacting auto rickshaw premium rates?</div>
    <div>
      These factors impact auto rickshaw premium rates:
      <ul>
        <li>Model, Engine & Make</li>
        <li>Auto rickshaw insurance depends on the model and make of your rickshaw, type of fuel, its manufacturing year and any add-on covers </li>
      <li>Geographic Location - primarily it depends on vehicle density</li>
        <li>Metros / large cities having huge /continuous traffic, crime and accident rates carry higher preium rates when compared to tier 2/3 cities /small towns </li>
        <li>No Claim Bonus (NCB)</li>
        <li>No-Claim Bonus: No-Claim Bonus implies that your auto rickshaw has not settled a single claim in the previous policy term. this enables you to claim NCB and hence discount on your OD premium  </li>
        <li>Comprehensive Package Policies carry higher premium than a third party only cover as the package policy covers damages /losses to own autorickshaw and its owner driver</li>
      </ul> 
    </div>
      <br/>
    <div className='font-bold'>Are passengers also covered in auto-rickshaw insurance?</div>
    <div>
      Yes - passengers are covered under both Comprehensive Package as well as standalone third party premium policy for auto rickshaws
    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    )
  }

}